export const NODE_ENV = process.env.NODE_ENV as string;
export const BASE_URL = process.env.BASE_URL as string;
export const GTM = process.env.VUE_APP_GTM as string;
export const GOOGLE_SITE_KEY = process.env.VUE_APP_GOOGLE_SITE_KEY as string;
export const CMS_URL = process.env.VUE_APP_CMS_URL as string;
export const DASHBOARD_URL = process.env.VUE_APP_DASHBOARD_URL as string;
export const WALLET_URL = process.env.VUE_APP_WALLET_URL as string;
export const WIDGET_URL = process.env.VUE_APP_WIDGET_URL as string;
export const DOCS_URL = process.env.VUE_APP_DOCS_URL as string;
export const WIDGET_ID = process.env.VUE_APP_WIDGET_ID as string;
export const PKG_ENV = process.env.VUE_APP_PKG_ENV as 'local' | 'dev' | 'prod';
export const API_URL = process.env.VUE_APP_API_URL as string;
export const MIXPANEL_TOKEN = process.env.VUE_APP_MIXPANEL_TOKEN as string;
