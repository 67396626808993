
import { Component, Vue } from 'vue-property-decorator';
import { ALT_TEXT, TITLES } from '@thxnetwork/public/utils/constants';
import { DASHBOARD_URL, WALLET_URL, DOCS_URL, WIDGET_URL } from '../config/secrets';

@Component({
    components: {},
})
export default class BaseNavbar extends Vue {
    walletUrl = WALLET_URL;
    widgetUrl = WIDGET_URL;
    docsUrl = DOCS_URL;
    toggle = false;
    ALT_TEXT = ALT_TEXT;
    TITLES = TITLES;
    isNavVisible = false;

    get isDarkJumbotron() {
        return (
            this.$route.path.startsWith('/demo') ||
            this.$route.path.startsWith('/token') ||
            this.$route.path.startsWith('/use-case')
        );
    }

    get dashboardUrl() {
        const url = new URL(DASHBOARD_URL);
        const ref = this.$route.query.ref;
        if (!ref) return url.toString();
        url.searchParams.append('referralCode', String(ref));
        return url.toString();
    }

    created() {
        window.addEventListener('scroll', this.onScroll);
    }

    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll() {
        this.toggle = window.scrollY > 50;
    }
}
