
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DASHBOARD_URL } from '../config/secrets';

@Component({
    name: 'BaseCardSignup',
    components: {},
})
export default class BaseCardSignup extends Vue {
    loading = false;
    isSuccess = false;
    error = '';
    email = '';

    @Prop({ default: `bg-white border-light` }) inputClass!: string;

    getValidationState({ dirty, validated, valid = null }: any) {
        return dirty || validated ? valid : null;
    }

    async submit() {
        let url = `${DASHBOARD_URL}/signup`;
        if (this.email) {
            url += `?signup_email=${this.email}`;
        }
        window.location.href = url;
    }
}
