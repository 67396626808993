
import { BButton, BJumbotron, BFormInput } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { ALT_TEXT, TITLES } from '@thxnetwork/public/utils/constants';
import { DASHBOARD_URL } from '../config/secrets';
import BaseCardSignup from './BaseCardSignup.vue';

@Component({
    components: {
        BaseCardSignup,
        'b-jumbotron': BJumbotron,
        'b-button': BButton,
        'b-form-input': BFormInput,
    },
    computed: mapGetters({
        metrics: 'metrics/all',
    }),
})
export default class BaseJumbotron extends Vue {
    dashboardUrl = DASHBOARD_URL;
    signupEmail = '';
    ALT_TEXT = ALT_TEXT;
    TITLES = TITLES;
}
